<template>
  <div class="general-login">
    <!-- top -->
    <div class="top">
      <span>CA登录</span>
      <img @click="$router.push({name:'CaLogin'})" src="../../assets/images/电脑.png" alt="图片加载失败" />
    </div>

    <!-- middle -->
    <div class="middle">
      <el-tabs stretch>
        <el-tab-pane label="密码登录">
          <pwd-login></pwd-login>
        </el-tab-pane>
        <el-tab-pane label="验证码登录">
          <code-login></code-login>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import PwdLogin from "@/components/GeneralLogin/PwdLogin.vue";
import CodeLogin from "@/components/GeneralLogin/CodeLogin.vue";

export default {
  components: { PwdLogin, CodeLogin }
};
</script>

<style lang="less" scoped>
.general-login {
  // top
  .top {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-bottom: 10px;
    span {
      font-size: 14px;
      color: #737373;
      width: 80px;
      line-height: 30px;
      text-align: center;
      background: url("../../assets/images/圆角矩形 1.png");
      background-size: 100% 100%;
    }
    img {
      width: 60px;
      height: 58px;
      cursor: pointer;
    }
  }

  // middle
  .middle {
    width: 280px;
    margin: 0 auto;
  }
}
</style>