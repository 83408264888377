<template>
  <div class="pwd-login">
    <el-form :model="form">
      <el-form-item label>
        <el-input v-model="form.account" placeholder="帐号"></el-input>
      </el-form-item>
      <el-form-item label>
        <el-input type="password" v-model="form.pwd" autocomplete="off" placeholder="密码"></el-input>
        <p class="forget-pwd">
          <router-link to>忘记密码</router-link>
        </p>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" round class="login-btn">登 录</el-button>
        <p class="at-once-register">
          没有帐号?
          <el-button type="text" size="small">立即注册</el-button>
        </p>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {}
    };
  },
  methods: {
    onSubmit() {}
  }
};
</script>

<style lang="less" scoped>
.pwd-login {
  .el-form {
    .el-form-item {
      .forget-pwd {
        color: #333333;
        font-size: 12px;
        text-align: right;
      }
      .login-btn {
        font-size: 16px;
        width: 270px;
        height: 40px;
      }
      .at-once-register {
        font-size: 12px;
        text-align: center;
      }
    }
  }
}
</style>