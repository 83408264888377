<template>
  <div class="code-login">
    <el-form :model="form">
      <el-form-item label>
        <el-input v-model="form.phone" placeholder="手机号"></el-input>
      </el-form-item>
      <el-form-item label>
        <el-input v-model="form.code" placeholder="验证码">
          <template slot="append">
            <el-button
              size="small"
              @click="getCode"
              :disabled="getCodeText!=='获取验证码'"
              class="get-code"
            >{{getCodeText}}</el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" round class="login-btn">登 录</el-button>
        <p class="at-once-register">
          没有帐号?
          <el-button type="text" size="small">立即注册</el-button>
        </p>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  beforeDestroy() {
    clearInterval(this.timeId);
  },
  data() {
    return {
      form: {},
      getCodeText: "获取验证码",
      timeId: null
    };
  },
  methods: {
    // 获取验证码
    getCode() {
      let num = 10;
      this.getCodeText = `重发验证(${num--}s)`;
      // 定时器
      this.timeId = setInterval(() => {
        this.getCodeText = `重发验证(${num--}s)`;
        console.log("timeId", this.timeId);
        if (num < 0) {
          clearInterval(this.timeId);
          this.getCodeText = "获取验证码";
        }
      }, 1000);
    },

    // 提交登录表单
    onSubmit() {}
  }
};
</script>

<style lang="less" scoped>
.code-login {
  .el-form {
    // 获取验证码
    .get-code {
      width: 110px;
      font-size: 12px;
    }
    .el-form-item {
      // 登录按钮
      .login-btn {
        font-size: 16px;
        width: 270px;
        height: 40px;
      }
      // 立即注册
      .at-once-register {
        font-size: 12px;
        text-align: center;
      }
    }
  }
}
</style>